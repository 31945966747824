<template>
  <div>
    <modal-detalles :data-convocatoria="dataConvocatoriaDetalles" />
    <convocatoria-modal-add-new @actualizarTabla="actualizarTabla" />
    <comunicados-modal
      :id-convocatoria="idConvocatoria"
      @actualizarTabla="actualizarTabla"
    />

    <div
      v-if="!$can('social.becas.convocatoria.index', 'api')"
      class="mt-2"
    >
      <b-link
        class="ml-4 d-flex align-items-center"
        href="/"
      >
        <b-img
          fluid
          :src="icono"
          width="30"
        />
        <h2 class="brand-text text-primary ml-1">
          <strong> {{ titulo }}</strong>
        </h2>

      </b-link>
      <h3 class="text-center text-primary mb-1">
        <strong>CONVOCATORIAS BIENESTAR UNIVERSITARIO</strong>
      </h3>
    </div>

    <b-card
      no-body
      class="p-2 mb-1"
      :class="$can('social.becas.convocatoria.index', 'api') ? '' : 'mx-4'"
    >
      <b-row>
        <b-col
          v-for="(item,index) in botones"
          :key="index"
          class="mx-0 px-0"
          xl="2"
          lg="2"
          md="3"
          sm="6"
          xs="12"
        >
          <b-button
            size="md"
            :variant="btnActivo === item.codigo ? resolveEstadoVariant(item.codigo) : 'flat-secondary'"

            block
            @click="filtrarConvocatorias(item.codigo)"
          >
            <feather-icon :icon="item.icon" />
            {{ item.nombre }}
          </b-button>
        </b-col>
        <b-col
          xl="2"
          lg="2"
          md="3"
          sm="6"
          xs="12"
        >
          <b-dropdown
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :text="textHistorial"
            :variant="btnActivo === 'concluido' || btnActivo === 'cancelado' ? 'danger' : 'flat-secondary'"
            block
            size="md"
          >
            <b-dropdown-item @click="filtrarConvocatorias('concluido')">
              Concluido
            </b-dropdown-item>
            <b-dropdown-item @click="filtrarConvocatorias('cancelado')">
              Cancelado
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <convocatorias-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0 px-2"
      :class="`${$can('social.becas.convocatoria.index', 'api') ? '' : 'mx-4'} border-${resolveEstadoVariant(filtroEstado)}`"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                size="md"
                type="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="400"
              />
              <b-button
                v-if="$can('social.becas.convocatoria.store', 'api')"
                variant="outline-success"
                @click="nuevaConvocatoria"
              >
                <span class="text-nowrap">Nueva Convocatoria</span>
                <!-- <feather-icon icon="PlusSquareIcon" /> -->
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        id="my-table"
        ref="refTablaConvocatorias"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        :no-provider-filtering="true"
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="tableColumns"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :height="myProvider.length < 5 ? '200px' : null"
      >

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <!-- Column: servicios -->
        <template #cell(servicios)="data">
          <div
            v-for="xx in data.item.serviciosx"
            :key="xx.codigo"
            class="text-nowrap"
          >
            <feather-icon
              :icon="resolveServicioIcon(xx.codigo)"
              size="15"
              class="mr-50"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            />
            <span
              class="align-text-top text-capitalize"
              :class="`text-${resolveServicioVariant(xx.codigo)}`"
            >{{ xx.nombre }} ({{ xx.n_becas }})</span>
          </div>
        </template>

        <!-- Column: comunicados -->
        <template #cell(comunicados)="data">
          <b-button
            v-for=" (val,index) in data.item.comunicados"
            :key="index"
            :href="val.archivo"
            target="_blank"
            size="sm"
            variant="outline-danger"
            block
          >
            <feather-icon
              icon="FileTextIcon"
              size="10"
            />
            <span style="font-size:9px">
              {{ val.nombre }}
            </span>
            <br>
            <span style="font-size:8px">
              {{ new Intl.DateTimeFormat('es-PE',{month: 'numeric', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(val.created_at)) }}
            </span>
          </b-button>
        </template>

        <!-- Nombre -->
        <template #cell(nombre)="data">
          <span class="text-primary"><strong>{{ data.item.nombre }}</strong></span>
          <br>
          <b-button
            size="sm"
            block
            variant="outline-primary"
            style="padding:3px"
            @click="actualizarDataConvocatoriaDetalles(data.item)"
          >
            Detalles
          </b-button>
        </template>

        <!-- Column: estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ data.item.estado }}
          </b-badge>
        </template>

        <!-- Incripcion -->
        <template #cell(inscripcion)="data">
          <span class="text-primary"><strong>Inicio: </strong></span>{{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(data.item.fecha_inicio)) }} <br>
          <span class="text-primary"><strong>Fin: </strong></span>{{ new Intl.DateTimeFormat('es-PE',{month: 'long', day:'numeric', year:'numeric', hour:'numeric', minute: 'numeric'}).format(new Date(data.item.fecha_fin)) }} <br>
        </template>

        <!-- Column: estado -->
        <template #cell(accion)="data">
          <div v-if="$can('social.becas.convocatoria.store', 'api')">
            <b-button
              v-if="data.item.estado !== 'borrador'"
              :variant="data.item.estado == 'cancelado' ? 'danger' : 'info'"
              size="sm"
              block
              :to="{name: 'bu-convocatoria-postulantes', params: {id: data.item.id}}"
            >Postulantes
              <feather-icon
                icon="UsersIcon"
                size="12"
                class="align-middle text-white"
              />
            </b-button>

            <b-button
              v-if="data.item.estado == 'concluido'"
              variant="outline-info"
              size="sm"
              block
              :href="data.item.archivo_resultado"
              :target="'_blank'"
            >Resultados
              <feather-icon
                icon="ClipboardIcon"
                size="12"
                class="align-middle"
              />
            </b-button>

            <!-- <div
              v-else-if="data.item.estado == 'cancelado'"
              class="text-danger"
            >
              Cancelado
            </div> -->
          </div>
          <div v-else>
            <div v-if="data.item.estado == 'en curso' || data.item.estado == 'vigente'">
              <b-button
                :variant="data.item.estado !== 'vigente' ? 'secondary' : 'info'"
                size="sm"
                block
                :disabled="data.item.estado !== 'vigente' ? true : false"
                @click="postularConvocatoria(data.item.id)"
              >Postular
                <feather-icon
                  icon="ArrowRightIcon"
                  size="12"
                  class="align-middle text-white"
                />
              </b-button>
            </div>
            <div v-else-if="data.item.estado == 'concluido'">
              <b-button
                variant="outline-info"
                size="sm"
                block
                :href="data.item.archivo_resultado"
                :target="'_blank'"
              >Resultados
                <feather-icon
                  icon="ClipboardIcon"
                  size="12"
                  class="align-middle"
                />
              </b-button>
            </div>
            <div
              v-else
              class="text-danger"
            >
              Cancelado
            </div>
          </div>
        </template>

        <!-- Column: Configuracion -->
        <template
          #cell(configuracion)="data"
        >
          <b-dropdown
            variant="dark"
            class="btn-icon rounded-circle ml-1"
            no-caret
            size="sm"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="SettingsIcon"
                size="16"
                class="align-middle"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Atender</span>
            </b-dropdown-item> -->

            <!-- <b-dropdown-item
              @click="postularConvocatoria(data.item.id)"
            >
              <feather-icon icon="ArrowRightCircleIcon" />
              <span class="align-middle ml-50">Postular</span>
            </b-dropdown-item> -->
            <b-dropdown-item
              v-if="data.item.estado === 'borrador' || data.item.estado === 'vigente' || data.item.estado === 'en curso'"
              @click="editConvocatoria(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.estado === 'vigente' || data.item.estado === 'en curso'"
              @click="comunicados(data.item.id)"
            >
              <feather-icon icon="Volume2Icon" />
              <span class="align-middle ml-50">Comunicados</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.estado === 'vigente' || data.item.estado === 'en curso' || data.item.estado === 'concluido'"
              @click="cancelarConvocatoria(data.item.id)"
            >
              <feather-icon icon="XOctagonIcon" />
              <span class="align-middle ml-50">Cancelar convocatoria</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.estado === 'borrador' || data.item.estado === 'cancelado'"
              @click="eliminarConvocatoria(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <convocatoria-list-postulantes /> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BSpinner, BLink, BImg,
  BBadge, BDropdown, BDropdownItem, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
// import useConvocatoriasList from './useConvocatoriasList'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import convocatoriaStoreModule from './convocatoriasStoreModule'
import ComunicadosModal from '../comunicados/ComunicadosModal.vue'
import ConvocatoriaModalAddNew from './ConvocatoriaModalAddNew.vue'
import ModalDetalles from './ModalDetalles.vue'

export default {
  components: {
    BImg,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    // BMedia,
    // BAvatar,
    // BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    vSelect,

    // externs
    ConvocatoriaModalAddNew,
    ComunicadosModal,
    ModalDetalles,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 300, 500],
      sortBy: 'id',
      sortDesc: false,
      // sortDirection: 'desc',
      filter: '',
      filterOn: [],

      dataConvocatoriaDetalles: {},
      idConvocatoria: 0,
      btnActivo: 'todos',
      textHistorial: 'Historial',
      botones: [
        { nombre: 'Todos', codigo: 'todos', icon: 'MenuIcon' },
        { nombre: 'Borrador', codigo: 'borrador', icon: 'MehIcon' },
        { nombre: 'Vigentes', codigo: 'vigente', icon: 'SmileIcon' },
        { nombre: 'En curso', codigo: 'en curso', icon: 'ClockIcon' },
      ],
      filtroEstado: 'todos',
      tableColumns: [
        { key: 'configuracion', label: 'conf.' },
        { key: 'nombre', label: 'nombre', sortable: true },
        { key: 'estado', label: 'estado', sortable: true },
        { key: 'servicios', label: 'servicios', sortable: true },
        { key: 'inscripcion', label: 'Inscripción (inicio-fin)', sortable: true },
        { key: 'comunicados', label: 'comunicados', sortable: true },
        { key: 'accion', label: 'acción', sortable: true },
      ],
    }
  },
  computed: {
    ...mapState('ajustesApp', ['titulo', 'icono']),

  },
  async beforeCreate() {
    if (localStorage.getItem('routeRedirectLaterLogin')) {
      localStorage.removeItem('routeRedirectLaterLogin')
      window.location.reload()
    }
  },
  created() {
    if (!this.$can('social.becas.convocatoria.store', 'api')) {
      const myIndex = this.tableColumns.indexOf(this.tableColumns.find(val => val.key === 'configuracion'))
      if (myIndex !== -1) {
        this.tableColumns.splice(myIndex, 1)
      }
      const myIndex2 = this.botones.indexOf(this.botones.find(val => val.codigo === 'borrador'))
      if (myIndex2 !== -1) {
        this.botones.splice(myIndex2, 1)
      }
    }
  },

  methods: {
    actualizarTabla() {
      this.filter = null
      this.$refs.refTablaConvocatorias.refresh()
    },
    async myProvider(ctx) {
      let promise = null
      if (this.$can('social.becas.convocatoria.store', 'api')) {
        promise = store.dispatch('convocatoria-module/getConvocatorias', { estado: this.filtroEstado, queryParams: ctx })
      } else {
        promise = store.dispatch('convocatoria-module/getConvocatoriasSinBorrador', { estado: this.filtroEstado, queryParams: ctx })
      }
      // const promise = this.$http.get('/servicio-social/beca/convocatoria', { queryParams: ctx })
      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data
        // Pluck the array of items off our axios response
        const items = data
        this.totalRows = total || 0
        // this.from = meta.pagination.count
        this.from = from || 0
        this.to = to || 0

        return items || []
      })
    },
    nuevaConvocatoria() {
      this.$emit('nuevaConvocatoria')
    },
    editConvocatoria(idConvocatoria) {
      store.dispatch('convocatoria-module/getConvocatoria', idConvocatoria)
      // this.$http.get(`/servicio-social/beca/convocatoria/${idConvocatoria}`)
        .then(res => {
          // this.dataConvocatoria = res.data
          this.$emit('editarConvocatoria', res.data)
        })
    },
    eliminarConvocatoria(idConvocatoria) {
      this.$swal({
        title: 'Esta seguro de eliminar la convocatoria',
        text: 'considere que esta acción es irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar convocatoria',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('convocatoria-module/deleteConvocatoria', idConvocatoria)
          // this.$http.delete(`/servicio-social/beca/convocatoria/${idConvocatoria}`)
            .then(() => {
              this.actualizarTabla()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Convocatoria eliminada.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error en eliminar convocatoria',
                  icon: 'CheckCircleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    postularConvocatoria(idConvocatoria) {
      if (!this.$can('social.becas.convocatoria.preinscripcion.store', 'api')) {
        this.modalConfirmacionLogin()
        return
      }
      store.dispatch('convocatoria-module/createPreinscripcion', idConvocatoria)
        .then(() => {
          this.$router.push({ name: 'bu-convocatorias-postular', params: { id: idConvocatoria } })
        }).catch(error => {
          // console.error('Error al crear preinscripción: ', error.response)
          if (error.response.status === 409) {
            this.$swal({
              icon: 'info',
              title: '¡Información!',
              text: error.response.data.error,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    comunicados(idConvocatoria) {
      // this.$emit('abrirModalComunicados', convocatoriaId)
      this.idConvocatoria = idConvocatoria
      this.$bvModal.show('modal-convocatoria-comunicados')
    },
    filtrarConvocatorias(codigo) {
      this.filtroEstado = codigo
      this.actualizarTabla()
      this.btnActivo = codigo
      if (codigo === 'concluido') {
        this.textHistorial = 'Concluidos'
      }
      if (codigo === 'cancelado') {
        this.textHistorial = 'Cancelados'
      }
      if (codigo !== 'concluido' && codigo !== 'cancelado') {
        this.textHistorial = 'Historial'
      }
    },
    modalConfirmacionLogin() {
      this.boxOne = ''
      this.$bvModal
        .msgBoxConfirm('Para postular debe de iniciar sesión', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            localStorage.setItem('routeRedirectLaterLogin', 'social-convocatorias-list')
            this.$router.push({ name: 'auth-login' })
          }
        })
    },
    cancelarConvocatoria(idConvocatoria) {
      const data = {
        idConvocatoria,
        dataEstado: 'cancelado',
      }

      this.$swal({
        title: 'Esta seguro de cancelar la convocatoria',
        text: 'considere que esta acción es irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Cancelar convocatoria',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('convocatoria-module/actualizarEstadoConvocatoria', data)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Convocatoria cancelada.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.actualizarTabla()
            }).catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error en cancelar convocatoria',
                  icon: 'CheckCircleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    actualizarDataConvocatoriaDetalles(item) {
      this.dataConvocatoriaDetalles = item
      this.$bvModal.show('modal_detalles_convocatoria')
    },
  },
  setup() {
    const CONVOCATORIA_APP_STORE_MODULE_NAME = 'convocatoria-module'

    // Register module
    if (!store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.registerModule(CONVOCATORIA_APP_STORE_MODULE_NAME, convocatoriaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONVOCATORIA_APP_STORE_MODULE_NAME)) store.unregisterModule(CONVOCATORIA_APP_STORE_MODULE_NAME)
    })

    // const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Comedor', value: 'Comedor' },
      { label: 'Residencia', value: 'Residencia' },
      { label: 'Bolsa de trabajo', value: 'Bolsa de trabajo' },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'borrador') return 'secondary'
      if (status === 'vigente') return 'success'
      if (status === 'en curso') return 'warning'
      if (status === 'concluido') return 'danger'
      if (status === 'cancelado') return 'danger'
      return 'primary'
    }
    const resolveServicioVariant = value => {
      if (value === 'BECA-COMEDOR') return 'primary'
      if (value === 'BECA-RESIDENCIA') return 'success'
      if (value === 'BECA-BOLSA') return 'info'
      return 'primary'
    }
    const resolveServicioIcon = value => {
      if (value === 'BECA-COMEDOR') return 'CoffeeIcon'
      if (value === 'BECA-RESIDENCIA') return 'HomeIcon'
      if (value === 'BECA-BOLSA') return 'BriefcaseIcon'

      return 'UserIcon'
    }

    return {
      convocatoriaStoreModule,
      avatarText,

      resolveServicioVariant,
      resolveServicioIcon,
      resolveEstadoVariant,

      roleOptions,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.per-page-selector {
  width: 90px;
}
</style>
