<template>
  <!-- modal login-->
  <b-modal
    id="modal_detalles_convocatoria"
    title="Detalles convocatoria"
    size="lg"
    :hide-footer="true"
  >
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <b-form-group
          class="text-danger font-weight-bold "
          label="NOMBRE CONVOCATORIA:"
        >
          <h4>{{ dataConvocatoria.nombre }}</h4>
        </b-form-group>
      </b-col>
      <b-col
        v-if="dataConvocatoria.descripcion"
        md="6"
        sm="12"
      >
        <b-form-group
          class="text-danger font-weight-bold "
          label="DESCRIPCIÓN:"
        >
          <span class="text-secondary"> {{ dataConvocatoria.descripcion }}</span>
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        sm="12"
      >
        <b-form-group
          class="text-danger font-weight-bold "
          label="SERVICIOS:"
        >
          <ul>
            <li
              v-for="(servicio, index) in dataConvocatoria.serviciosx"
              :key="index"
              class="text-secondary"
            >
              {{ servicio.nombre }} ({{ servicio.n_becas }} becas)
            </li>
          </ul>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-form-group
          class="text-danger font-weight-bold "
          label="FECHAS:"
        >
          <div class="text-secondary">
            <span> <strong>FECHA INICIO:</strong>  </span> {{ dataConvocatoria.fecha_inicio }}
          </div>
          <div class="text-secondary">
            <span> <strong>FECHA FIN:</strong> </span> {{ dataConvocatoria.fecha_fin }}
          </div>
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <b-form-group
          class="text-danger font-weight-bold "
          label="COMISIÓN:"
        >
          <label>{{ dataConvocatoria.comision }}</label>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
  },

  props: {
    dataConvocatoria: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {

    }
  },

}
</script>

<style lang="scss">
</style>
