<template>
  <div>
    <b-modal
      id="modal-convocatoria-comunicados"
      title="Comunicados"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mb-1 pr-1 mt-0"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="16"
            />
            <span class="align-middle ml-50">Cargar comunicados</span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit="formSubmit"
          >
            <b-row>
              <b-col
                lg="6"
                md="6"
                xs="12"
                class="mb-1"
              >
                <b-form-input
                  v-model="form.nombre"
                  size="sm"
                  placeholder="Nombre del comunicado"
                  required
                />
              </b-col>
              <b-col
                lg="6"
                md="6"
                xs="12"
                class="mb-1"
              >

                <b-form-file
                  v-model="form.file"
                  size="sm"
                  :browse-text="'Seleccionar archivo'"
                  :placeholder="'No se eligió archivo'"
                  required
                  accept=".pdf"
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                  </template>
                </b-form-file>

              </b-col>

              <b-col
                cols="12"
                class="mb-1"
              >
                <b-form-textarea
                  v-model="form.descripcion"
                  size="sm"
                  placeholder="Escriba la descripción aqui ..."
                />
              </b-col>

              <b-col
                lg="12"
                md="12"
                xs="12"
              >
                <b-button
                  variant="primary"
                  class=" px-1"
                  size="sm"
                  style="padding: 8px 10px"
                  block
                  type="submit"
                >
                  Guardar comunicado
                  <feather-icon
                    icon="SaveIcon"
                    size="14"
                    class="m-0 p-0"
                  />
                </b-button>
              </b-col>

            </b-row>
          </b-form>
        </b-card-body>
      </b-card>

      <!-- COMUNICADOS TABLE -->
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="Volume2Icon"
              size="16"
            />
            <span class="align-middle ml-50">Lista de comunicados</span>
          </b-card-title>
        </b-card-header>

        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="'No hay registros para mostrar'"
          show-empty
          head-variant="dark"
          small
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Cargando...</strong>
            </div>
          </template>

          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>

          <template #cell(ver)="row">
            <b-button
              variant="outline-info"
              size="sm"
              :href="row.item.archivo"
              :target="'_blank'"
            >
              ver
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="DownloadIcon"
                size="13"
              />
            </b-button>
          </template>
          <template #cell(eliminar)="row">
            <b-button
              size="sm"
              variant="outline-danger"
              @click="eliminarComunicado(row.item, row.index)"
            >
              eliminar
              <feather-icon
                :id="`invoice-row-5-preview-icon`"
                icon="Trash2Icon"
                size="13"
              />
            </b-button>
          </template>

        </b-table>
      </b-card>
      <b-button
        variant="outline-secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$bvModal.hide('modal-convocatoria-comunicados')"
      >
        Cerrar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  onUnmounted, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BCard, BRow, BFormTextarea, BCol, BTable, BButton, BBadge, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody, BFormInput, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import comunicadosStoreModule from './comunicadoStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BFormTextarea, BTable, BBadge, BButton, BFormFile, BForm, BCardHeader, BCardTitle, BCardBody, BFormInput, BSpinner,
  },
  props: {
    idConvocatoria: {
      type: Number,
      default: 0,
    },

  },
  data() {
    return {

      form: {
        nombre: null,
        servidor_publico_id: null,
      },
      blankForm: {
        nombre: null,
        descripcion: null,
        file: null,
      },
      anexos: [],
      showModal: true,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },

  methods: {
    eliminarComunicado(item, index) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, borrar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const { idConvocatoria } = this
          const idComunicado = item.id
          store.dispatch('comunicados-module/deleteComunicado', { idConvocatoria, idComunicado })
            .then(response => {
              this.$emit('actualizarTabla')
              this.items.splice(index, 1)
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: `Tu archivo ${response.data.nombre} ha sido eliminado.`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          //   // fin store
        }
      })
    },

    formSubmit(e) {
      e.preventDefault()

      const data = new FormData()
      data.append('archivo', this.form.file)
      data.append('nombre', this.form.nombre)
      data.append('descripcion', this.form.descripcion)
      store.dispatch('comunicados-module/addComunicado', { data, id: this.idConvocatoria })
        .then(response => {
          this.items.push(response.data)
          this.$emit('actualizarTabla')
          this.form = { ...this.blankForm }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comunicado cargado exitosamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            error.response.data.error.archivo.forEach(value => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: '¡Advertencia!',
                  text: value,
                  icon: 'XOctagonIcon',
                  variant: 'warning',
                },
              })
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Falló la carga, error 500',
                icon: 'XOctagonIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
  setup(props) {
    const COMUNICADOS_STORE_MODULE_NAME = 'comunicados-module'

    // Register module
    if (!store.hasModule(COMUNICADOS_STORE_MODULE_NAME)) store.registerModule(COMUNICADOS_STORE_MODULE_NAME, comunicadosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMUNICADOS_STORE_MODULE_NAME)) store.unregisterModule(COMUNICADOS_STORE_MODULE_NAME)
    })

    const { idConvocatoria } = toRefs(props)
    const isBusy = ref(true)
    const items = ref([])
    const fields = [
      {
        key: 'index', label: 'N°', sortable: true, sortDirection: 'desc',
      },
      { key: 'nombre', label: 'Nombre', sortable: true },
      { key: 'descripcion', label: 'Descripción', sortable: true },
      { key: 'ver', label: 'ver' },
      { key: 'eliminar', label: 'eliminar' },
    ]
    watch(idConvocatoria, () => {
      //
      store.dispatch('comunicados-module/fetchComunicados', idConvocatoria.value)
        .then(response => {
          items.value = response.data.data
          isBusy.value = false
        })
    })

    return {
      items,
      fields,
      isBusy,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
