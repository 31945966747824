<template>
  <b-modal
    id="modal_add_new_convocatoria"
    ok-only
    size="lg"
    class="p-5"
    :header-bg-variant="cabezeraModal"
    hide-footer
  >
    <template
      #modal-header
    >
      <div class="text-white">
        <strong class="float-left">
          {{ nombreModal }}
        </strong></div>

    </template>
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            lg="12"
            md="12"
            sm="12"
            xs="12"
          >
            <!-- nombre convocatoria -->
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Nombre de la convocatoria"
                label-for="nombre_convocatoria"
              >
                <b-form-input
                  id="nombre_convocatoria"
                  v-model="convocatoriaData.nombre"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Convocatoria 20.."
                />

                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
            xs="12"
          >
            <validation-provider
              #default="validationContext"
              name="semestre"
              rules="required"
            >
              <b-form-group
                label="Semestre:"
                label-for="semestre_convocatoria"
              >
                <b-form-select
                  id="semestre_convocatoria"
                  v-model="convocatoriaData.semestre_id"
                  :state="getValidationState(validationContext)"
                  :options="optionsSemestres"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            lg="6"
            md="6"
            sm="6"
            xs="12"
          >
            <validation-provider
              #default="validationContext"
              name="semestre_promedio"
              rules="required"
            >
              <b-form-group
                label="Semestre a considerar la NOTA PROMEDIO:"
                label-for="semestre_promedio"
              >
                <b-form-select
                  id="semestre_promedio"
                  v-model="convocatoriaData.semestre_promedio"
                  :state="getValidationState(validationContext)"
                  :options="optionsSemestresAnteriores"
                />
                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col cols="12">
            <!-- Descripción -->

            <b-form-group
              label="Descripción (opcional)"
              label-for="descripcion"
            >
              <b-form-textarea
                id="descripcion"
                v-model="convocatoriaData.descripcion"
                size="sm"

                placeholder="Describa aquí.."
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Fecha inicio -->
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Fecha Inicio</label>
              <flat-pickr
                v-model="convocatoriaData.fecha_inicio"
                class="form-control"
                :config="{ locale:Spanish, enableTime: true,dateFormat: 'Y-m-d H:i'}"
                @input="() => {convocatoriaData.fecha_fin = null}"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label>Fecha Fin</label>
              <flat-pickr
                v-model="convocatoriaData.fecha_fin"
                class="form-control"
                :config="{ locale:Spanish, enableTime: true,dateFormat: 'Y-m-d H:i', disable:[{from:'1900-01-01',to:convocatoriaData.fecha_inicio == null ? null : convocatoriaData.fecha_inicio}]}"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <validation-provider
          #default="validationContext"
          name="User Role"
          rules="required"
        >
          <b-form-group
            label="Servicios"
            label-for="servicios-select"
            :state="getValidationState(validationContext)"
          >
            <b-row
              class="rounded p-1 mx-1"
              :class="validationContext.errors[0] ? 'border-danger' : 'border'"
            >
              <b-col
                cols="12"
                class="text-center"
              >
                <b-form-checkbox
                  v-for="(option,index) in serviciosOptions"
                  :key="index"
                  v-model="convocatoriaData.servicios"
                  :value="option.value"
                  name="flavour-3a"
                  inline
                  class="mr-1"
                  :disabled="checkDisabled"
                  @change="selectServicio(option.value)"
                >
                  {{ option.value.nombre }}
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              <small
                v-if="validationContext.errors[0]"
                class="pl-2"
              >
                Seleccionar un servicio como mínimo
              </small>

            </b-form-invalid-feedback>
            <b-table-simple
              striped
              bordered
              borderless
              small
              responsive
              class="mt-1 px-2"
            > <!-- head-variant="dark" -->
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>N°</b-th>
                  <b-th>Servicio</b-th>
                  <b-th>Vacantes</b-th>

                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(item, index) of convocatoriaData.servicios"
                  :key="index"
                >
                  <b-td>{{ index+1 }}</b-td>
                  <b-td>{{ item.nombre }}</b-td>
                  <b-td>
                    <b-form-input
                      v-model="convocatoriaData.servicios[index].n_becas"
                      size="sm"
                      :value="item.n_becas"
                      style="width:60px"
                    />
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <br>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          rules="required"
        >
          <b-form-group
            label="Requisitos"
            label-for="servicios-select"
            :state="getValidationState(validationContext)"
          >
            <!-- <label for="">Requisitos:</label> -->
            <b-row
              class="rounded p-1 mx-1"
              :class="validationContext.errors[0] ? 'border-danger' : 'border'"
            >
              <b-col
                cols="12"
                class="text-center"
              >

                <b-form-checkbox
                  v-for="option in requisitosOptions"
                  :key="option.codigo"
                  v-model="convocatoriaData.requisitos"
                  :value="option.value"
                  name="flavour-3a"
                  inline
                  class="mx-2"
                  :disabled="checkDisabled"
                >
                  {{ option.text }}
                  <br>
                  <span>
                    <b-button
                      variant="outline-secondary"
                      target="_blank"
                      style="padding: 2px 10px;"
                      :to="{name: 'formulario-x', params: {id_formulariox: option.idFormulario}}"
                    ><small>ver formulario</small>
                    </b-button>
                  </span>

                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              <small
                v-if="validationContext.errors[0]"
                class="pl-2"
              >
                Seleccionar 1 requisito como mínimo
              </small>

            </b-form-invalid-feedback>

          </b-form-group>
        </validation-provider>
        <br>
        <validation-provider
          #default="validationContext"
          name="comision"
          rules="required"
        >
          <b-form-group
            label="Miembros de la Comisión"
            label-for="comision"
          >
            <b-form-textarea
              id="comision"
              v-model="convocatoriaData.comision"
              :state="getValidationState(validationContext)"
              placeholder="Listar aquí.."
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>

        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between mt-3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            size="sm"
            @click="$bvModal.hide('modal_add_new_convocatoria')"
          >
            <feather-icon
              icon="XIcon"
              size="14"
            />
            Cancelar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="`outline-${cabezeraModal}`"
            class="mr-2"
            type="submit"
          >
            {{ nombreBotonModal }}
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
            />
          </b-button>
        </div>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <span />
    </template>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea,
  BTableSimple, BThead, BTr, BTh, BTd, BTbody, BFormCheckbox, BCol, BRow, BFormSelect,
} from 'bootstrap-vue'
// import { mapState } from 'vuex'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es'
// import vSelect from 'vue-select'
// import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    BFormSelect,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BFormInput,
    BCol,
    BRow,
    BTr,
    BTh,
    BTd,
    BModal,
    // BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,

    ValidationObserver,
    ValidationProvider,
    // vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      Spanish,
      required,
      vamosCrear: true,
      idConvocSelect: 0,

      nombreModal: '',
      nombreBotonModal: '',
      cabezeraModal: '',
      checkDisabled: false,
      serviciosDisabled: false,
      fechInicioDisabled: false,
      convocatoriaData: {
        servicios: [],
        requisitos: [],
        fecha_fin: null,
        fecha_inicio: null,
      },
      requisitosOptions: [],
      serviciosOptions: [],
      optionsSemestres: [],
      optionsSemestresAnteriores: [],
    }
  },
  async mounted() {
    if (this.$can('social.becas.convocatoria.store', 'api')) {
      await store.dispatch('convocatoria-module/getSemestresPromedio').then(res => {
        this.optionsSemestresAnteriores = res.data.map(value => ({
          value: value.id, text: value.nombre,
        }))
      })

      await store.dispatch('convocatoria-module/getSemestres').then(res => {
        this.optionsSemestres = res.data.map(value => ({
          value: value.id, text: value.nombre,
        }))
      })

      await store.dispatch('convocatoria-module/getServicios').then(res => {
        this.serviciosOptions = res.data.map(value => ({
          label: value.nombre,
          value: {
            servicio_id: value.id, n_becas: 0, nombre: value.nombre,
          },
        }))
      })

      await store.dispatch('convocatoria-module/getRequisitos').then(res => {
        this.requisitosOptions = res.data.map(val => ({
          text: val.nombre,
          value: { codigo: val.codigo, requerido: 1 },
          idFormulario: val.id,
        }))
      })
    }
  },
  created() {
    this.$parent.$on('editarConvocatoria', this.editarConvocatoria)
    this.$parent.$on('nuevaConvocatoria', this.nuevaConvocatoria)
  },
  methods: {
    selectServicio(servicio) {
      this.serviciosOptions.forEach((value, index) => {
        if (value.value.servicio_id === servicio.servicio_id) {
          this.serviciosOptions[index].value.n_becas = 0
        }
      })
    },
    onSubmit() {
      if (this.convocatoriaData.fecha_inicio === '' || this.convocatoriaData.fecha_fin === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Complete fecha de inicio y fin',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (this.convocatoriaData.servicios.some(value => parseInt(value.n_becas, 10) === 0)) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: '¡Advertencia!',
            text: 'El número de becas de un servicio no debe de ser cero',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (this.vamosCrear) {
        this.createConvocatoria()
      } else {
        this.updateConvocatoria(this.idConvocSelect)
      }
    },
    createConvocatoria() {
      this.$swal({
        title: '¿Está seguro de crear una nueva convocatoria?',
        text: 'Una vez iniciada la convocatoria, los formularios no podrán ser editados',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Entiendo, crear convocatoria!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const arrayData = JSON.parse(JSON.stringify(this.convocatoriaData))
          // eslint-disable-next-line no-param-reassign
          arrayData.servicios.forEach(value => { delete value.nombre })
          store.dispatch('convocatoria-module/addConvocatoria', arrayData)
          // this.$http.post('/servicio-social/beca/convocatoria', arrayData)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Convocatoria creada exitosamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$emit('actualizarTabla')
              this.$bvModal.hide('modal_add_new_convocatoria')
            }).catch(() => {

            })
        }
      })
    },
    updateConvocatoria(idConvoc) {
      const arrayData = JSON.parse(JSON.stringify(this.convocatoriaData))
      // eslint-disable-next-line no-param-reassign
      arrayData.servicios.forEach(value => { delete value.nombre })
      store.dispatch('convocatoria-module/updateConvocatoria', { idConvoc, arrayData })
      // this.$http.put(`/servicio-social/beca/convocatoria/${idConvoc}`, arrayData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Convocatoria actualizada!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTabla')
          this.$bvModal.hide('modal_add_new_convocatoria')
        })
    },
    limpiarFormulario() {
      this.convocatoriaData = {
        nombre: '',
        descripcion: '',
        fecha_inicio: '',
        fecha_fin: '',
        servicios: [],
        requisitos: [
        ],
        // semestre: 65,
      }
    },
    nuevaConvocatoria() {
      this.nombreModal = 'Nueva convocatoria'
      this.nombreBotonModal = 'Grabar convocatoria'
      this.cabezeraModal = 'primary'
      this.checkDisabled = false
      this.serviciosDisabled = false
      this.fechInicioDisabled = false
      this.vamosCrear = true

      this.limpiarFormulario()

      if (this.optionsSemestres.length) {
        this.convocatoriaData.semestre_id = this.optionsSemestres[this.optionsSemestres.length - 1].value
      }
      this.requisitosOptions.forEach(val => this.convocatoriaData.requisitos.push(val.value))
      this.$bvModal.show('modal_add_new_convocatoria')
    },

    editarConvocatoria(dataEditConvocatoria) {
      this.nombreModal = 'Editar convocatoria'
      this.nombreBotonModal = 'Actualizar convocatoria'
      this.cabezeraModal = 'warning'
      this.vamosCrear = false
      this.idConvocSelect = dataEditConvocatoria.id

      if (dataEditConvocatoria.estado !== 'borrador') {
        this.serviciosDisabled = true
        this.fechInicioDisabled = true
        this.checkDisabled = true
      } else {
        this.serviciosDisabled = false
        this.fechInicioDisabled = false
        this.checkDisabled = false
      }
      this.limpiarFormulario()
      this.convocatoriaData.nombre = dataEditConvocatoria.nombre
      this.convocatoriaData.descripcion = dataEditConvocatoria.descripcion
      this.convocatoriaData.comision = dataEditConvocatoria.comision
      this.convocatoriaData.fecha_inicio = dataEditConvocatoria.fecha_inicio
      this.convocatoriaData.fecha_fin = dataEditConvocatoria.fecha_fin
      this.convocatoriaData.semestre_id = dataEditConvocatoria.semestre_id
      this.convocatoriaData.semestre_promedio = dataEditConvocatoria.semestre_promedio

      // recuperamos los servicios
      dataEditConvocatoria.serviciosx.forEach((value, index) => {
        this.convocatoriaData.servicios.push(this.serviciosOptions.find(val => val.value.servicio_id === value.id).value)
        this.convocatoriaData.servicios[index].n_becas = value.n_becas
      })

      // recuperamos los requisitos
      dataEditConvocatoria.requisitosx.forEach(value => {
        // if (value.requerido === 1) {
        this.convocatoriaData.requisitos.push(this.requisitosOptions.find(val => val.value.codigo === value.codigo).value)
        // }
        // this.convocatoriaData.servicios[index].n_becas = value.n_becas
      })
      this.$bvModal.show('modal_add_new_convocatoria')
    },

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
// .vs__selected{
//   background-color: #ff9f43;
// }
</style>
