import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getConvocatorias(ctx, { estado, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/beca/convocatoria?estado=${estado}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getConvocatoriasSinBorrador(ctx, { estado, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/beca/convocatoria?estado=${estado}&sin_borrador=${true}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getConvocatoria(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/beca/convocatoria/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addConvocatoria(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-social/beca/convocatoria', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateConvocatoria(ctx, { idConvoc, arrayData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-social/beca/convocatoria/${idConvoc}`, arrayData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteConvocatoria(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/beca/convocatoria/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // MODAL ADD CONVOCATORIA
    getSemestres() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/show-semestres')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSemestresPromedio() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/show-semestres-anteriores')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getServicios() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/show-servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRequisitos() {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-social/beca/show-requisitos')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // PREINSCRIPCION
    createPreinscripcion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/convocatoria/${id}/pre-inscripcion`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // cancelar convocatoria
    actualizarEstadoConvocatoria(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/convocatoria/${data.idConvocatoria}/actualizar-estado`, { estado: data.dataEstado })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
