import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // index
    fetchComunicados(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-social/beca/convocatoria/${id}/comunicado`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addComunicado(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-social/beca/convocatoria/${params.id}/comunicado`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteComunicado(ctx, { idConvocatoria, idComunicado }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-social/beca/convocatoria/${idConvocatoria}/comunicado/${idComunicado}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
